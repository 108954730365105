import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Text,
  Space,
  Hr,
} from 'components'
import Layout from 'components/Layout'
import LeadCTAButton from 'components/LeadCTAButton'

import Members from './members-new'
import Tabs from './Tabs'
import Links from './Links'
import Map from 'screens/Home/components/Map'

import backgroundImage from 'images/about-bg.png'

const seoTags = {
  title: 'Team Bambus - wir vermitteln Kredite',
  description:
    'Bambus.io kauft bis zu 50% Ihres Hauses an - Sie bleiben hr Leben lang drin wohnen! Teilverkauf mit Rückkaufsrecht. Jetzt unverbindlich anfragen!',
}

const Page = () => {
  const { illustration } = useStaticQuery(
    graphql`
      query {
        illustration: file(relativePath: { eq: "about-illustration.png" }) {
          childImageSharp {
            fluid(maxWidth: 760, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Layout
      seo={{ title: seoTags.title, description: seoTags.description }}
      headerProps={{ bg: 'colorBlue2' }}
    >
      <Section
        bg="darkNavy100"
        sx={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: ['auto', null, 372],
          display: 'flex',
          alignItems: 'center',
        }}
        py={[0, 0, 4, 8]}
      >
        <Container
          sx={{
            position: 'relative',
          }}
        >
          <Row justifyContent="center">
            <Column size={[1, null, 10 / 12, 8 / 12]}>
              <Box
                sx={{
                  position: ['relative', null, 'absolute'],
                  top: [24, 30, -32, -89],
                  right: [28, 38, -140, -160],
                  width: [320, 420, 640, 760],
                  mt: [-3, -4, 0, 0],
                  // maxHeight: [220, null, 'auto', null],
                }}
              >
                <ProgressiveImage fluid={illustration.childImageSharp.fluid} />
              </Box>
              <Box sx={{ maxWidth: [null, null, 480, 540] }}>
                <Space mb={4}>
                  <Heading.H2 as="h1" color="white">
                    Unsere Mission
                  </Heading.H2>
                  <Text color="white">
                    Wir geben Ihnen die Möglichkeit, Ihr Immobilienvermögen so
                    zu nutzen, wie es zu Ihren Bedürfniessen in Ihrer aktuellen
                    Lebensphase passt.
                  </Text>
                </Space>
              </Box>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row justifyContent="center">
            <Column size={[1, null, 10 / 12, 8 / 12]}>
              <Box sx={{ maxWidth: 600 }}>
                <Space mb={4}>
                  <Heading.H3 as="h2">Unsere Lösung</Heading.H3>
                  <Text>
                    Immobilienvermögen ist ein sicheres Investment. Sie wohnen
                    günstig im Eigentum und haben finanziell vorgesorgt. Doch in
                    bestimmten Lebensphasen kann es wichtig werden, auf dieses
                    Vermögen zuzugreifen. Genau dafür bietet die Bambus
                    Immobilien GmbH das Konzept des Teilverkaufs. Gemeinsam mit
                    uns profitieren Sie von Ihrem Immobilienbesitz, um Ihr Leben
                    optimal zu gestalten.
                  </Text>
                </Space>

                <Hr my={5} bg="transparent" />

                <Space mb={4}>
                  <Heading.H3 as="h2">Unser Angebot</Heading.H3>
                  <Text>
                    Bei einem Teilverkauf zahlt Ihnen Bambus (der Käufer) den
                    Wert des verkauften Anteils aus und wird als Miteigentümer
                    Ihrer Immobilie im Grundbuch eingetragen. Im Alltag ändert
                    sich für Sie praktisch gar nichts. Sie wohnen weiterhin in
                    der Immobilie und können den verkauften Anteil jederzeit
                    wieder zurückkaufen. Sie können auch jederzeit den
                    Gesamtverkauf der Immobilie erwirken. Bambus verrechnet
                    Ihnen für die Nutzung des verkauften Anteils eine klar
                    definierte und faire Nutzungsgebühr.
                  </Text>
                </Space>
              </Box>

              <Hr my={5} bg="transparent" />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LeadCTAButton
                  variant="v2Main1"
                  ctaButtonID="Team--Intro"
                  width={['100%', 'auto']}
                >
                  Unverbindliches Angebot einholen
                </LeadCTAButton>
              </Box>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section py={[0, 0]}>
        <Container
          sx={{ borderTop: '1px solid', borderColor: 'colorBlue10' }}
        ></Container>
      </Section>

      <Members />

      <Section bg="colorBlue2">
        <Container>
          <Row justifyContent="center">
            <Column size={[1, null, 10 / 12, 8 / 12]}>
              <Space mb={4}>
                <Heading.H3 as="h2">Über Bambus</Heading.H3>
                <Text>
                  Die <b>Bambus Immobilien GmbH</b> ist ein unabhängiges
                  Unternehmen mit Sitz in Österreich und Deutschland. Wir geben
                  Ihnen als Immobilienbesitzer Ihren Bedürfnissen entsprechend
                  klare, gezielte Liquiditätsoptionen und begleiten Sie bei
                  Ihrem individuellen Bedarf.{' '}
                  <b>
                    Franz Hoerhager und Mag. Sebastian Pfisterer arbeiten seit
                    2018
                  </b>{' '}
                  an der Optimierung von Vermögenswerten in Immobilien und haben
                  bereits mehrere tausend Anträge in zwei Ländern bearbeitet.
                </Text>
              </Space>
            </Column>
          </Row>
        </Container>
      </Section>

      <Map />
      <Tabs />
      <Links />
    </Layout>
  )
}
export default Page
