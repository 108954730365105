import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'
import shuffle from 'lodash/shuffle'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Text,
  Space,
} from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const Members = () => {
  const images = useStaticQuery(
    graphql`
      query {
        franz: file(
          relativePath: { eq: "team/headshots/new/franz-white.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        basti: file(
          relativePath: { eq: "team/headshots/new/basti-white.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Section>
      <Container>
        <Section.Header>
          <Row justifyContent="center">
            <Column size={[1, null, 10 / 12, 8 / 12]}>
              <Space mb={4}>
                <Heading.H3 as="h2" textAlign="center">
                  Die Geschäftsführung
                </Heading.H3>
                <Text textAlign="center">
                  Profitieren Sie von dem langjährigen Know-how unserer Experten
                  in Österreich und Deutschland. Wir sind gerne für Sie da!
                </Text>
              </Space>
            </Column>
          </Row>
        </Section.Header>
        <Section.Body
          sx={{
            '&:after': {
              content: '""',
              display: 'block',
              paddingTop: '1px',
              marginTop: '-1px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'stretch',
              gap: 10,
            }}
          >
            <TeamMember name="Franz Hoerhager" image={images.franz} />
            <TeamMember name="Mag. Sebastian Pfisterer" image={images.basti} />
          </Box>
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }}>
          <LeadCTAButton
            ctaButtonID="Team--Members"
            variant="v2Main2"
            width={['100%', 'auto']}
          >
            Kontaktieren Sie uns
          </LeadCTAButton>
        </Section.Footer>
      </Container>
    </Section>
  )
}

const TeamMember = ({ image, name }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: 400,
          width: 300,
        }}
      >
        <ProgressiveImage
          fluid={image?.childImageSharp.fluid}
          style={{
            flex: 1,
            borderRadius: 12,
          }}
          imgStyle={{
            userSelect: 'none',
            height: 400,
            pointerEvents: 'none',
          }}
        />
        <Text
          textAlign="center"
          sx={{
            fontWeight: 700,
          }}
        >
          {name}
        </Text>
      </Box>
    </>
  )
}

export default Members
