import React from 'react'

import ButtonBar from 'components/ButtonBar'
import LeadCTAButton from 'components/LeadCTAButton'
import { Section, Container, Row, Column, Box, Button, Text } from 'components'

const Tabs = () => {
  const [selectedTab, setSelectedTab] = React.useState(content[0].id)
  const tabs = content.map(({ title, id }) => ({
    id,
    children: title,
    onClick: setSelectedTab,
  }))
  return (
    <Section bg="colorBlue2">
      <Container>
        <Row justifyContent="center">
          <Column size={[1, null, 10 / 12, 8 / 12]}>
            <ButtonBar
              buttons={tabs}
              selectedId={selectedTab}
              breakOnMobile
              fullWidth
            />
            <Text py={3}>
              {content.find(({ id }) => id === selectedTab)?.children}
            </Text>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LeadCTAButton
                ctaButtonID="Team--Tabs"
                variant="v2Main2"
                sx={{
                  mx: ['auto', 0],
                }}
              >
                Jetzt kostenlose Beratung sichern
              </LeadCTAButton>
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

const content = [
  {
    id: 1,
    title: 'Unsere Geschichte',
    children: (
      <>
        <p>
          Immobilien schränken ihre Besitzer ein. Sie sind illiquide und
          verhindern dadurch eine flexible Veranlagungsstrategie. Dadurch können
          Immobilienbesitzer oftmals ihr Vermögen nicht so verwalten, wie es
          professionelle Investoren tun.
        </p>
        <p>
          Bambus arbeitet seit 2018 an der Lösung dieses Problems. Darauf folgte
          die Aufnahme in das Inkubationsprogramm des FinTech Accelerator F10.
          Anfang 2019 konnte eine Gruppe renommierter Angelinvestoren von der
          Vision überzeugt werden und die erste Finanzierungsrunde für Bambus
          wurde erfolgreich abgeschlossen.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'Unser Name',
    children: (
      <>
        <p>
          Was hat der Firmenname mit Immobilien-Finanzierungen zu tun? Bambus
          ist extrem robust. Er kommt sogar als Bauträger zum Einsatz. Das
          Verhältnis von Eigengewicht gegenüber Nutzlast übertrifft Stahlbeton.
          Stärke & Verlässlichkeit sind daher die Eigenschaften, die wir auch
          mit unserem Unternehmen verbinden. Einige Bambusarten wachsen sogar
          schneller als alle anderen Pflanzen. Genauso möchten wir auch
          zukunftsorientiert wachsen, um noch mehr Menschen einen einfachen
          Zugang zu finanzieller Flexibilität & Liquidität zu verschaffen.
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: 'Unsere Vision',
    children: (
      <>
        <p>Immobilienliquidität für alle!</p>
        <p>
          In Zukunft sollen alle Immobilienbesitzer frei mit Immobilienvermögen
          umgehen können. Mit wenigen Klicks und ohne Aufwand.
        </p>
      </>
    ),
  },
]

export default Tabs
